import React, { useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { useMutation } from '@apollo/client';

import { ASSOCIATE_MFA_TOKEN, MFA_SETUP, MFA_VERIFY } from 'api';
import { authClient } from 'index';
import { SkeletonLoader } from 'components/common/Loader';

import {
	ButtonRow,
	SubTitle,
	FormContainer,
	QustomQRCode,
	MfaMsg,
} from './styledComponents';
import { CHALLENGE } from './constants';

const MFA = ({
  userData = { ChallengeParameters: {} },
	setUserData,
	setError,
}) => {
	const [mfaData, setMfaData] = useState();

	const { ChallengeParameters: { USER_ID_FOR_SRP } } = userData;

	const [associateMfaToken] = useMutation(
		ASSOCIATE_MFA_TOKEN,
		{
			fetchPolicy: 'no-cache',
			client: authClient,
			onCompleted: (data) => {
				setMfaData(data.associateMfaToken);
			},
			onError: setError,
		}
	);

	const [mfaVerify, { loading: mfaVerifying }] = useMutation(MFA_VERIFY, {
		client: authClient,
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			setUserData(data.mfaVerify);
		},
		onError: setError,
	});

	const [setUpMfa, { loading }] = useMutation(
		MFA_SETUP,
		{
			fetchPolicy: 'no-cache',
			client: authClient,
			onCompleted: (data) => {
				setUserData(data.mfaSetup);
			},
			onError: setError,
		}
	);

	useEffect(() => {
		const { Session, ChallengeName } = userData;

		if (ChallengeName === CHALLENGE.MFA_SETUP) {
			associateMfaToken({
				variables: {
					session: Session,
				},
			}).catch((e) => {
				console.error('Error to associate MFA token: ', e)
			});
		}
	}, []);

	const handleSubmit = ({ code }) => {
		if (userData.ChallengeName === CHALLENGE.MFA_SETUP) {
			setUpMfa({
				variables: {
					username: USER_ID_FOR_SRP,
					session: mfaData.Session,
					code,
				},
			}).catch((e) => {
				console.error('Error in MFA form: ', e)
			});
		}

		if (userData.ChallengeName === CHALLENGE.SOFTWARE_TOKEN_MFA
			|| userData.ChallengeName === CHALLENGE.EMAIL_TOKEN_MFA) {
			mfaVerify({
				variables: {
					username: userData.ChallengeParameters.USER_ID_FOR_SRP,
					mfa: code,
					session: userData.Session,
					challengeName: userData.ChallengeName,
				},
			}).catch((e) => {
				console.error('Error in MFA form: ', e)
			});
		}
	};

	const handleBack = (e) => {
		e.preventDefault();
		setError(undefined);
		setUserData(undefined);
	};

	const isEmailMfa = userData.ChallengeName === CHALLENGE.EMAIL_TOKEN_MFA;
	const isProduction = process.env.REACT_APP_ENV === 'production';
	const mfaValue = mfaData && `otpauth://totp/AWSCognito:${USER_ID_FOR_SRP}?secret=${mfaData.SecretCode}\
&issuer=AdvitoSSO${isProduction ? '' : '-test'}`;

	return (
		<>
			<SubTitle>Multi-factor authentication</SubTitle>
			<FormContainer>
				<Form onFinish={handleSubmit}>
					{loading || mfaVerifying
						? <SkeletonLoader />
						: (
							<>
								{userData.ChallengeName === CHALLENGE.MFA_SETUP
								&& mfaValue
								&& <QustomQRCode value={mfaValue} renderAs="svg" />}
								<Form.Item
									name="code"
									rules={[
										{ required: true, message: 'Please input OTP (MFA code) code!' }
									]}
								>
									<Input type="number" placeholder="123456" />
								</Form.Item>
								{isEmailMfa && (
									<MfaMsg>
										Temporary OTP (MFA code) code was sent by e-mail. It will expire in 5 minutes.
									</MfaMsg>
								)}
							</>
						)
					}
					<Form.Item>
						<ButtonRow>
							<Button
								htmlType="button"
								onClick={handleBack}
								disabled={mfaVerifying || loading}
							>
								Back
							</Button>
							<Button
								htmlType="submit"
								type="danger"
								disabled={mfaVerifying || loading}
							>
								Submit
							</Button>
						</ButtonRow>
					</Form.Item>
				</Form>
			</FormContainer>
		</>
	);
}

export default MFA;

import React, { useState } from 'react'
import styled from 'styled-components'
import { useQuery, useLazyQuery } from '@apollo/client'
import { SpinLoader } from 'components/common/Loader'
import ErrorMessage from 'components/common/ErrorMessage'
import { Progress } from 'antd'
import { GET_JOB, DOWNLOAD_ERROR_FILE } from 'api/queries'
import { getSectionText, getSectionHeader } from './helper'
import { Message } from './'

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const ErrorCount = styled.div`
	font-weight: bold;
	font-size: 1.3em;
`

const Section = styled.div`
	margin: 10px 0;
`

const JobProgress = ({ job, MessageHeading, setParentMessage }) => {
	const [message, setMessage] = useState({})
	const [errorOffset, setErrorOffset] = useState(0)
	const [prevErrorOffset, setPrevErrorOffset] = useState(0)
	const [downloadErrorFile] = useLazyQuery(DOWNLOAD_ERROR_FILE, {
		onCompleted: ({ downloadErrorFile }) => {
			const element = document.createElement('a')
			element.setAttribute('href', downloadErrorFile.url)
			element.setAttribute('download', downloadErrorFile.key)
			element.target = '_blank'
			element.click()
		},
		onError: (err) => {
			setParentMessage({
				message: (
					<MessageHeading>{err.message}</MessageHeading>
				),
				type: 'error'
			})
		}
	})

	const onErrorsDownload = (jobId) => {
		downloadErrorFile({
			variables: {
				links: [{
					jobId
				}],
			}
		})
	}

	const { loading, error, data, stopPolling } = useQuery(GET_JOB, {
		variables: { jobId: job.id },
		skip: !job.id,
		pollInterval: 3000,
		fetchPolicy: 'network-only'
	})
	if (loading) return <SpinLoader />
	if (error) return <ErrorMessage error={error} />
	const {
		originalFileName,
		jobName,
		countRows,
		isComplete,
		jobStatus,
		jobNote,
		processingStartTimestamp,
		templateName,
		applicationName
	} = data.getJob
	if (isComplete) {
		stopPolling()
		if (jobStatus === 'done' || jobStatus === 'ingested') {
			//send success
			!message.message && setMessage({
				message: (
					<MessageHeading>
						Your {originalFileName} has been successfully uploaded! <br /> It
						contained {countRows} rows and has the job name {jobName}
					</MessageHeading>
				),
				type: 'success'
			})
		} else if (jobStatus === 'error') {
			const json = JSON.parse(jobNote)
			const errorCount = Object.values(json).reduce(
				(prev, curr) => prev + curr.length,
				0
			)
			const displayArray = []
			for (let [key, value] of Object.entries(json)) {
				const count = value.length
				if (count === 1) {
					displayArray.push(
						<Section key={key}>{getSectionText(key, value[0])}</Section>
					)
				} else if (count > 1) {
					displayArray.push(getSectionHeader(key, count))
					for (let idx = 0; idx < errorOffset + 10 && idx < value.length; idx++) {
						displayArray.push(
							<div key={idx}>
								- {idx === errorOffset + 9 && errorOffset + 10 < errorCount ? `${value[idx]}...` : value[idx]}
							</div>
						);
					}
				}
			}
			const emailArray = []
			for (let [key, value] of Object.entries(json)) {
				const count = value.length
				const formatKey = (key, count) => {
					if (key === 'unmaskedCreditCardData') {
						return `Unmasked credit card data (${count} errors)`
					}
					if (key === 'sourceCurrencyCode') {
						return `No source currency code (${count} errors)`
					}
					if (key === 'incorrectCharacters') {
						return `Non-English or unallowed character used (${count} errors)`
					}
					if (key === 'incorrectDates') {
						return `Incorrect date format (${count} errors)`
					}
					if (key === 'stateShouldBeBlank') {
						return `Incorrect State value, State should be blank (${count} errors)`
					}
					if (key === 'stateMissing') {
						return `Incorrect State value, State is missing (${count} errors)`
					}
					if (key === 'stateInvalid') {
						return `Incorrect State value, State code is invalid (${count} errors)`
					}
				}
				const formattedKey = formatKey(key, count)

				if (count > 0) {
					emailArray.push(
						` %0D%0A ${formattedKey} : ${[
							value.map((v) => v.replace(',', ' ')).join(', ')
						]}` + `%0D%0A`
					)
				}
			}

			const getMailTo = () => {
				const user = JSON.parse(localStorage.getItem('advito-user')).displayName

				return `mailto:AdvitoServices@bcdtravel.eu?subject= Advito I%26A Ingestion Console Assistance Request
				&body=Please provide a detailed description of your need so that we can provide prompt assistance.%0D%0A
				%0D%0A
				Username: ${user}
				%0D%0A
				Filename: ${originalFileName}
				%0D%0A
				Practice Area Selection: ${applicationName}
				%0D%0A
				Template: ${templateName}
				%0D%0A
				Date/time of ingestion attempt: ${new Date(+processingStartTimestamp)}
				%0D%0A
				Error generated: %0D%0A
				  ${emailArray.join('')}
				`
			}

			(!message.message || prevErrorOffset !== errorOffset) && setMessage({
				message: (
					<>
						<ErrorCount>{errorCount} Errors found</ErrorCount>
						<MessageHeading>
							Your file will not be ingested due to the following errors:
						</MessageHeading>
						{displayArray}
						<div>
							{errorOffset + 10 < errorCount && (
								<React.Fragment>
									<a href="javascript:void(0);" onClick={() => setErrorOffset(errorOffset + 10)}>Show more</a>
									{' '}or{' '}
								</React.Fragment>
							)}
							{window.apiBaseURL !== '{{api-base-url}}' && (
								<a href="javascript:void(0);" onClick={() => onErrorsDownload(job.id)}>Download errors</a>
							)}
						</div>

						<br />
						<br />
						<MessageHeading>
							Please resolve the errors and try uploading again.
						</MessageHeading>
						<div>
							Tried everything and still getting errors?{' '}
							<a href={getMailTo()}>Contact I&amp;A</a> within 5 days of upload
							for assistance.
						</div>
					</>
				),
				type: 'error'
			})

			prevErrorOffset !== errorOffset && setPrevErrorOffset(errorOffset)
		} else {
			const getMailTo = () => {
				const user = JSON.parse(localStorage.getItem('advito-user')).displayName

				return `mailto:AdvitoServices@bcdtravel.eu?subject= Advito I%26A Ingestion Console Assistance Request
				&body=Please provide a detailed description of your need so that we can provide prompt assistance.%0D%0A
				%0D%0A
				Username: ${user}
				%0D%0A
				Filename: ${originalFileName}
				%0D%0A
				Practice Area Selection: ${applicationName}
				%0D%0A
				Template: ${templateName}
				%0D%0A
				Date/time of ingestion attempt: ${new Date(+processingStartTimestamp)}
				%0D%0A

				`
			}

			!message.message && setMessage({
				message: (
					<>
						<ErrorCount>There was a server error. We will contact you by e-mail as soon as we solve the issue.</ErrorCount>
						<MessageHeading>{jobNote}</MessageHeading>
						<div>
							Tried everything and still getting errors?{' '}
							<a href={getMailTo()}>Contact I&amp;A</a> within 5 days of upload
							for assistance.
						</div>
					</>
				),
				type: 'error'
			})
		}
	}
	return (
		<React.Fragment>
			{jobStatus === 'running' && (
				<Container>
					<Progress type="circle" percent={jobNote ? jobNote : 0} />
				</Container>
			)}
			{message.message && (
				<Message message={message.message} type={message.type} showIcon />
			)}
		</React.Fragment>
	)
}

export default JobProgress

// need window.hostingBucketURL variable
// This component display deploy info, https://jiraeu.epam.com/browse/BCDTA-687

import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    color: rgb(102, 102, 102);
    background-color: rgb(246, 245, 244);
    border: 1px solid rgb(237, 237, 237);
    border-radius: 3px;
    z-index: 10000;
`;

const List = styled.ul`
    list-style: none;
    padding: 10px;
    margin: 0;
`;

const BannerHeader = styled.div`
    display: flex;
`;

const BannerTitle = styled.p`
    margin-right: 60px;
    margin-bottom: 0;
`;

const ButtonStyled = styled.button`
    position: absolute;
    border: 1px solid transparent;
    user-select: none;
    border-radius: 3px;
    outline: none;
    right: 0;
`;

const BannerInfoDeploy = () => {
    const [deployInfo, setDeployInfo] = useState(null);
    const [isVisible, setIsVisible] = useState(false);

    const deleteIndexHtmlFromHostingURL = () => {
        if (window.hostingBucketURL.match(/(index.html)/)) {
            window.hostingBucketURL = window.hostingBucketURL.replace(/(\/index.html)/, '')
        }
    };

    useEffect(() => {
        deleteIndexHtmlFromHostingURL();
        if (window.hostingBucketURL !== '{{hosting-bucket-url}}') {
            fetch(`${window.hostingBucketURL}/version.json`)
                .then(res => res.json())
                .then(setDeployInfo)
                .catch(error => {
                    console.error(error);
                });
        }
    }, []);

    return ( deployInfo && deployInfo.envname !== 'prod' &&
      (<Container>
          <BannerHeader>
              {!isVisible && (<BannerTitle>Deploy Info</BannerTitle>)}
              <ButtonStyled
                type='button'
                className='ant-btn-primary'
                onClick={() => setIsVisible(!isVisible)}
              >
                  {isVisible ? 'hide' : 'show'}
              </ButtonStyled>
          </BannerHeader>
          {isVisible && (<List>
              <li>App: {deployInfo.appname}</li>
              <li>Branch: {deployInfo.branchname}</li>
              <li>Env: {deployInfo.envname}</li>
              <li>Build: {deployInfo.buildnumber}</li>
              <li>Deploy date: {deployInfo.deploydate}</li>
              <li>Deploy time: {deployInfo.deploytime}</li>
          </List>)}
      </Container>)
    )
}

export default BannerInfoDeploy;
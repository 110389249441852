import gql from 'graphql-tag'

export const GET_JOB = gql`
	query getJob($jobId: Int!) {
		getJob(jobId: $jobId) {
			id
			originalFileName
			jobName
			countRows
			isComplete
			jobStatus
			jobNote
			timestamp
			processingStartTimestamp
			templateName
			applicationName
		}
	}
`

export const GET_PRESIGNED_UPLOAD_URL = gql`
	query getPresignedUploadUrl(
		$fileName: String!
		$applicationId: Int!
		$jobId: Int
	) {
		getPresignedUploadUrl(fileName: $fileName, applicationId: $applicationId, jobId: $jobId) {
			key
			url
		}
	}
`

export const DOWNLOAD_ERROR_FILE = gql`
	query DownloadErrorFile($links: [DownloadErrorFileInput]!) {
		downloadErrorFile(links: $links) {
			key
			url
		}
	}
`

export const SHOW_MORE_ERRORS = gql`
	query showMoreErrors(
		$jobId: Int!
		$bucket: String!
		$key: String!
	) {
		showMoreErrors(jobId: $jobId, bucket: $bucket, key: $key)
	}
`

import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Select } from 'antd';

import { SOURCE_LIST } from 'api/queries';
import { SpinLoader } from 'components/common/Loader';
import ErrorMessage from 'components/common/ErrorMessage';

import { FormSelect } from './StyledComponents';
import { getAnotherTemplate, isBatchNeeded } from './helper';

const { Option } = Select;

const SelectSource = ({ variables = null, label, onChange, application, setSources }) => {
	const [getSourceList, { loading, error, data }] = useLazyQuery(SOURCE_LIST, {
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			setSources(data.sourceList)
		}
	});

	useEffect(() => {
		const isBatchUpload = isBatchNeeded(application, variables.templateId);
		const secondTemplate = isBatchUpload ? getAnotherTemplate(application, variables.templateId) : null;

		getSourceList({
			variables: {
				templateIds: isBatchUpload
					? [variables.templateId, secondTemplate]
					: [variables.templateId]
			},
		})
	}, [variables.templateId, application]);

	if (!data || loading) return <SpinLoader />;
	if (error) return <ErrorMessage error={error} />;
	return (
		<FormSelect>
			<span>{label}</span>
			<Select onChange={onChange}>
				{data.sourceList[0].map((source, i) => {
					return (
						<Option key={'source' + i} value={source.id}>
							{source.sourceName}
						</Option>
					)
				})}
				{data.sourceList[0].length && application !== 1 && (
					<Option key={'unlisted'} value={0}>
						Source not listed
					</Option>
				)}
			</Select>
		</FormSelect>
	);
};

export default SelectSource;

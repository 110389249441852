import React from 'react';
import styled from 'styled-components';
import isString from 'lodash.isstring';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

import { APPLICATIONS, TEMPLATES } from 'constant';

const DAYS = 'days';
const DAY = 'day';
const DATE_FORMAT = 'MM/DD/YYYY';
const END = 'end';
const INCLUSIVITY = '[]';

const startCase = (value) => isString(value)
	? value.split(' ').map((item) => item.slice(0, 1).toUpperCase() + item.slice(1)).join(' ')
	: undefined;
const handleNullValue = (value) => value === null ? "null" : value;

export const ERRORS360 = {
	DATARANGE: 'daterange',
	COLUMNS_MISSING: 'columns_missing',
	NONE_NOT_ALLOWED: 'missing',
	ANY_STR_MIN_LENGTH: 'string_too_short',
	ANY_MIN_LENGTH: 'too_short',
	ANY_STR_MAX_LENGTH: 'string_too_long',
	ANY_MAX_LENGTH: 'too_long',
	DATE: 'date_type',
	DATE_TIME_TYPE: 'datetime_type',
	NUMBER_NOT_GT: 'greater_than',
	NUMBER_NOT_GE: 'greater_than_equal',
	NUMBER_NOT_LT: 'less_than',
	NUMBER_NOT_LE: 'less_than_equal',
	TIME: 'time_type',
	EMAIL: 'email_error',
	REGEX: 'string_pattern_mismatch',
	STRING: 'string_type',
	ENUM: 'enum',
	INTEGER: 'int_type',
	INTEGER_PARSING: 'int_parsing',
	INT_FROM_FLOAT: 'int_from_float',
	FLOAT: 'float_type',
	FLOAT_PARSING: 'float_parsing',
	DECIMAL: 'decimal_parsing',
	DECIMAL_MAX_PLACES: 'decimal_max_places',
	NOT_AVAILABLE: 'not_found',
	NOT_AVAILABLE_COUNTRY: 'value_error.not_found.country',
	NOT_AVAILABLE_CITY: 'value_error.not_found.city',
	NOT_AVAILABLE_STATE: 'value_error.not_found.state',
	NOT_AVAILABLE_REGION: 'value_error.not_found.region',
	ONE_OF_TWO_MANDATORY: 'one_of_two_mandatory',
	MANDATORY_IF: 'mandatory_if_another_column_value',
	MANDATORY_IF_NON_EMPTY: 'mandatory_if_another_column_non_empty',
	PCI: 'pci',
	WRONG_VALUES_COMBINATION: 'wrong_values_combination',
	ZERO_VALUE_ERROR: 'zero_value_error',
	LESS_THAN_VALUE_ERROR: 'less_than_value_error',
	NONE_CONDITION_ERROR: 'none_condition_error',
	HMF_ID_NOT_FOUND_ERROR: 'not_valid_hmf_id',
};

const Title = styled.div`
	font-weight: 400;
	text-decoration: underline;
`;

export const getSectionText = (key, text) => {
	if (key === 'unmaskedCreditCardData') {
		return <Title>The file contains unmasked credit card data ({text})</Title>;
	}
	if (key === 'sourceCurrencyCode') {
		return (
			<Title>The file does not contain source currency code ({text})</Title>
		);
	}
	if (key === 'incorrectCharacters') {
		return (
			<Title>
				The file contains non-english characters, only the following special
				characters are allowed:`&$(),: ({text})
			</Title>
		);
	}
	if (key === 'incorrectDates') {
		return (
			<Title>
				The file contains an incorrect date format, dates should be DD-MMM-YYYY
				({text})
			</Title>
		);
	}
	if (key === 'stateShouldBeBlank') {
		return <Title>The State value should be blank ({text})</Title>;
	}
	if (key === 'stateMissing') {
		return <Title>The State value is missing ({text})</Title>;
	}
	if (key === 'stateInvalid') {
		return <Title>Invalid two letter State Code ({text})</Title>;
	}
	if (key === 'incorrectTemplate') {
		return <Title>Invalid template format({text})</Title>;
	}
	if (key === 'missingRequired') {
		return <Title>Required fields are missing({text})</Title>;
	}
	if (key === 'dataType') {
		return <Title>Invalid data type({text})</Title>;
	}
	if (key === 'invalidCityName') {
		return <Title>Invalid city name({text})</Title>;
	}
	if (key === 'invalidCheckout') {
		return <Title>Invalid Check-Out dates({text})</Title>;
	}
	if (key === 'invalidSpend') {
		return <Title>Invalid spend amounts({text})</Title>;
	}
	if (key === 'fileExists') {
		return <Title>File has already been ingested({text})</Title>;
	}
	if (key === 'invalidCountryCode') {
		return <Title>Invalid Country Code({text})</Title>;
	}
};

export const getSectionHeader = (key, count) => {
	if (key === 'unmaskedCreditCardData') {
		return <Title key={key}>Unmasked credit card data ({count} errors)</Title>;
	}
	if (key === 'sourceCurrencyCode') {
		return <Title key={key}>No source currency code ({count} errors)</Title>;
	}
	if (key === 'incorrectCharacters') {
		return (
			<Title key={key}>
				Non-English or unallowed character used ({count} errors)
			</Title>
		);
	}
	if (key === 'incorrectDates') {
		return <Title key={key}>Incorrect date format ({count} errors)</Title>;
	}
	if (key === 'stateShouldBeBlank') {
		return (
			<Title key={key}>
				Incorrect State value, State should be blank ({count} errors)
			</Title>
		);
	}
	if (key === 'stateMissing') {
		return (
			<Title key={key}>
				Incorrect State value, State is missing ({count} errors)
			</Title>
		);
	}
	if (key === 'stateInvalid') {
		return (
			<Title key={key}>
				Incorrect State value, State code is invalid ({count} errors)
			</Title>
		);
	}
	if (key === 'incorrectTemplate') {
		return <Title key={key}>Invalid Template Format ({count} errors)</Title>;
	}
	if (key === 'missingRequired') {
		return <Title key={key}>Required Fields are Missing ({count} errors)</Title>;
	}
	if (key === 'dataType') {
		return <Title key={key}>Invalid Data Types ({count} errors)</Title>;
	}
	if (key === 'invalidCityName') {
		return <Title key={key}>Invalid City Name ({count} errors)</Title>;
	}
	if (key === 'invalidCheckout') {
		return <Title key={key}>Invalid Check-Out Dates ({count} errors)</Title>;
	}
	if (key === 'invalidSpend') {
		return <Title key={key}>Invalid Spend Amounts ({count} errors)</Title>;
	}
	if (key === 'fileExists') {
		return (
			<Title key={key}>File has Already Been Ingested ({count} errors)</Title>
		);
	}
	if (key === 'invalidCountryCode') {
		return <Title key={key}>Invalid Country Code ({count} errors)</Title>;
	}
};

export const getErrorText360 = item => {
	const row = item.row + 2;
	const startCaseColumnName = startCase(item.columnName);
    const inputNullValue = handleNullValue(item.input)

	switch (item.type) {
		case ERRORS360.DATARANGE:
			return <>File has been already ingested. Backout the current version to load a new one.</>;
		case ERRORS360.COLUMNS_MISSING:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> column is missed.</li>;
		case ERRORS360.NONE_NOT_ALLOWED:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> is required information and cannot be empty. Please fix data in row {row}.</li>;
		case ERRORS360.ANY_STR_MIN_LENGTH:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> value shall have min {item.ctx['min_length']} characters . <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue} . Please fix data in row {row}.</li>;
		case ERRORS360.ANY_MIN_LENGTH:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> value shall have min {item.ctx['min_length']} characters . <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue} . Please fix data in row {row}.</li>;
		case ERRORS360.ANY_STR_MAX_LENGTH:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> value shall have max {item.ctx['max_length']} characters . <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue} .Please fix data in row {row}.</li>;
		case ERRORS360.ANY_MAX_LENGTH:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> value shall have max {item.ctx['max_length']} characters . <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue} .Please fix data in row {row}.</li>;
		case ERRORS360.DATE:
		case ERRORS360.DATE_TIME_TYPE:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> data shall have date format in Excel or valid Pattern. <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue} . Please fix data in row {row}.</li>;
		case ERRORS360.NUMBER_NOT_GT:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> value shall not be less than {item.ctx['gt']}. <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue} . Please fix data in row {row}.</li>;
		case ERRORS360.NUMBER_NOT_GE:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> value shall not be less than {item.ctx['ge']}. <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue}. Please fix data in row {row}.</li>;
		case ERRORS360.NUMBER_NOT_LT:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> value shall not be higher than {item.ctx['lt']}. <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue}. Please fix data in row {row}.</li>;
		case ERRORS360.NUMBER_NOT_LE:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> value shall not be higher than {item.ctx['le']}. <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue}. Please fix data in row {row}.</li>;
		case ERRORS360.TIME:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> info shall have time format or valid Pattern. <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue}. Please fix data in row {row}.</li>;
		case ERRORS360.EMAIL:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> value shall be email address. Please fix data in row {row}.</li>;
		case ERRORS360.REGEX:
			return (item.ctx.pattern === `^(\\D*\\d?\\D*){8}$` ?
				<li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> info shall have max 8 not hidden numbers. <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue}. Please fix data in row {row}.</li> :
				<li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> info shall have {item.ctx.pattern} format. <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue}. Please fix data in row {row}.</li>);
		case ERRORS360.STRING:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> info shall have string format. <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue}. Please fix data in row {row}.</li>;
		case ERRORS360.ENUM:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> value shall belong to the predefined list [{item.ctx['expected']}]. <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue}. Please fix data in row {row}.</li>;
		case ERRORS360.INTEGER:
		case ERRORS360.INTEGER_PARSING:
		case ERRORS360.INT_FROM_FLOAT:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> info shall have integer format. <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue}. Please fix data in row {row}.</li>;
		case ERRORS360.FLOAT:
		case ERRORS360.FLOAT_PARSING:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> info shall have float format. <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue}. Please fix data in row {row}.</li>;
		case ERRORS360.DECIMAL:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> info shall have decimal format. <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue}. Please fix data in row {row}.</li>;
		case ERRORS360.DECIMAL_MAX_PLACES:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> value should have max {item.ctx['decimal_places']} places. <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue}. Please fix data in row {row}.</li>;
		case ERRORS360.PCI:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> value defined as credit card information in {row} row. Please, correct data and try uploading again.</li>;
		case ERRORS360.NOT_AVAILABLE:
		case ERRORS360.HMF_ID_NOT_FOUND_ERROR:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> info doesn't belong to the list of available in the system. <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue}. Please fix data in row {row}.</li>;
		case ERRORS360.NOT_AVAILABLE_COUNTRY:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> info doesn't belong to the list of countries available in the system. Please fix data in row {row}.</li>;
		case ERRORS360.NOT_AVAILABLE_CITY:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> info doesn't belong to the list of cities available in the system. Please fix data in row {row}.</li>;
		case ERRORS360.NOT_AVAILABLE_STATE:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> info doesn't belong to the list of states available in the system. Please fix data in row {row}.</li>;
		case ERRORS360.NOT_AVAILABLE_REGION:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> info doesn't belong to the list of regions available in the system. Please fix data in row {row}.</li>;
		case ERRORS360.ONE_OF_TWO_MANDATORY:
			return <li><b style={{ fontWeight: 'bold' }}>{startCase(item.ctx['another_column_name'])}</b> info or <b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> info shall be defined. Please fix data in row {row}.</li>;
		case ERRORS360.MANDATORY_IF:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> is required information if <b style={{ fontWeight: 'bold' }}>{startCase(item.ctx['another_column_name'])}</b> is {item.ctx['another_column_value']}. Please fix data in row {row}.</li>;
		case ERRORS360.MANDATORY_IF_NON_EMPTY:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> is required information if <b style={{ fontWeight: 'bold' }}>{startCase(item.ctx['another_column_name'])}</b> value is provided. Please fix data in row {row}.</li>;
		case ERRORS360.WRONG_VALUES_COMBINATION:
			return <li>Current combination of values is prohibited for columns <b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b>, <b style={{ fontWeight: 'bold' }}>{item.ctx['columns_names'].map((item) => startCase(item)).join(', ')}</b>.  <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue}. Please fix data in row {row}.</li>;
		case ERRORS360.ZERO_VALUE_ERROR:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> shall not be equal to zero. Please fix data in row {row}.</li>;
		case ERRORS360.LESS_THAN_VALUE_ERROR:
			return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> should be more or equal to <b style={{ fontWeight: 'bold' }}>{startCase(item.ctx['another_column_name'])}</b> <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue}. Please fix data in row {row}.</li>;
		case ERRORS360.NONE_CONDITION_ERROR:
		    return <li><b style={{ fontWeight: 'bold' }}>{startCaseColumnName}</b> field is mandatory in case of following combination <b style={{ fontWeight: 'bold' }}>Columns:</b> {item.ctx['columns'].map((item) => startCase(item)).join(', ')}, <b style={{ fontWeight: 'bold' }}>Values:</b> {item.ctx['values'].map((item) => startCase(item)).join(', ')} <b style={{ fontWeight: 'bold' }}>Input_value:</b> {inputNullValue}. Please fix data in row {row}.</li>;
		default:
			return <li />;
	}
}

export const getMomentRangeDates = (dateRanges) => {
	return dateRanges.map(dateRange => {
		return {
			dataStartDate: moment(new Date(Number(dateRange.dataStartDate))),
			dataEndDate: moment(new Date(Number(dateRange.dataEndDate)))
		};
	});
};

export const getArrayOfDisabledDates = (dateRanges) => {
	const copyDateRanges = cloneDeep(dateRanges).map((dateRange) =>
		({dataStartDate: moment(dateRange.dataStartDate), dataEndDate: moment(dateRange.dataEndDate)}));
	const dates = [];

	copyDateRanges.forEach((range) => {
		while (range.dataStartDate.isSameOrBefore(range.dataEndDate)) {
			dates.push(range.dataStartDate.clone());
			range.dataStartDate.add(1, DAYS);
		}
	});

	return dates;
};

export const getDisabledDates = (current, dateRanges, needDisable) => needDisable && dateRanges.some(
	(range) => current.isBetween(
		range.dataStartDate,
		range.dataEndDate.endOf(DAY),
		null,
		INCLUSIVITY,
	),
);

export const monthCellRender = (date, setNeedDisable) => {
	setNeedDisable(false);
	return <div className="ant-picker-cell-inner">{date.format('MMM')}</div>;
};

export const getDatePickerCells = (
	current,
	today,
	info,
	dateRanges,
	period,
	disabledClickCells,
	setDisabledClickCells,
	setNeedDisable,
) => {
	const formattedDate = current.format(DATE_FORMAT);
	const currentMomentDate = current.startOf(DAY);
	setNeedDisable(true);

	const handleOver = (event, momentDate) => {
		if (info.range === END) {
			const arrayDates = getArrayOfDisabledDates(dateRanges);
			const firstDate = period[0];
			const isForbiddenCell = arrayDates.some((date) =>
				date.isBetween(firstDate, momentDate, null, INCLUSIVITY));

			if (isForbiddenCell) {
				const { date } = event.target.dataset;

				setDisabledClickCells(prevHover => ({
					...prevHover,
					[date]: true
				}));
			}
		}
	};

	const handleOut = (event) => {
		const { date } = event.target.dataset;

		if (disabledClickCells[date]) {
			setDisabledClickCells(prevHover => ({
				...prevHover,
				[date]: false
			}));
		}
	};

	const handleClick = (event) => {
		const { date } = event.target.dataset;

		if (disabledClickCells[date]) {
			event.stopPropagation();
		}
	};

	const classNames = disabledClickCells[formattedDate]
		? 'ant-picker-cell-inner hover-disabled'
		: 'ant-picker-cell-inner';

	return (
		<>
			<div
				onMouseOver={(event) => handleOver(event, currentMomentDate)}
				onMouseOut={handleOut}
				onClick={handleClick}
				className={classNames}
				data-date={formattedDate}
			>
				{current.date()}
			</div>
		</>
	);
};

export const isBatchNeeded = (applicationId, templateId) => {
	return (applicationId === APPLICATIONS.AIR && templateId === TEMPLATES.AIR_TICKET)
			|| (applicationId === APPLICATIONS.AIR && templateId === TEMPLATES.AIR_SEGMENT)
			|| (applicationId === APPLICATIONS.ANALYTICS_360 && templateId === TEMPLATES.ANALYTICS_360_AIR)
			|| (applicationId === APPLICATIONS.ANALYTICS_360 && templateId === TEMPLATES.ANALYTICS_360_AIR_SEGMENT);
};

export const getAnotherTemplate = (application, templateId) => {
	if (application === APPLICATIONS.AIR && templateId === TEMPLATES.AIR_TICKET) {
		return TEMPLATES.AIR_SEGMENT;
	} else if (application === APPLICATIONS.AIR && templateId === TEMPLATES.AIR_SEGMENT) {
		return TEMPLATES.AIR_TICKET;
	} else if (application === APPLICATIONS.ANALYTICS_360 && templateId === TEMPLATES.ANALYTICS_360_AIR) {
		return TEMPLATES.ANALYTICS_360_AIR_SEGMENT;
	} else if (application === APPLICATIONS.ANALYTICS_360 && templateId === TEMPLATES.ANALYTICS_360_AIR_SEGMENT) {
		return TEMPLATES.ANALYTICS_360_AIR;
	}
};

const getAnotherSourceId = (source, sources) => {
	const currentSourceName = sources[0].find((src) => src.id === source).sourceName;
	const secondSource = sources[1].find((src) => src.sourceName === currentSourceName);
	return secondSource.id;
};

export const getCurrentSource = (template, inputs, sources) => {
	const { source, template: templateId } = inputs;

	if ((template === 'ticket') &&
		(templateId === TEMPLATES.AIR_TICKET || templateId === TEMPLATES.ANALYTICS_360_AIR)) {
		return source;
	}

	if ((template === 'segment') &&
		(templateId === TEMPLATES.AIR_SEGMENT || templateId === TEMPLATES.ANALYTICS_360_AIR_SEGMENT)) {
		return source;
	}

	if ((template === 'ticket') &&
		(templateId === TEMPLATES.AIR_SEGMENT || templateId === TEMPLATES.ANALYTICS_360_AIR_SEGMENT)) {
		return getAnotherSourceId(source, sources);
	}

	if ((template === 'segment') &&
		(templateId === TEMPLATES.AIR_TICKET || templateId === TEMPLATES.ANALYTICS_360_AIR)) {
		return getAnotherSourceId(source, sources);
	}
};

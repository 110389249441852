import {
  CLEAR_TO_INITIAL,
  FAIL_LAST_ACTIVE_STEP,
  UPDATE_STEP_PROGRESS,
  SET_VISIBILITY,
} from './constants';

const progressBarReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STEP_PROGRESS:
      const handledStepIndex = state.steps.findIndex((step) => step.label === action.payload.label);
      return {
        ...state,
        steps: state.steps.reduce((acc, step, idx) => {
          if (idx < handledStepIndex) {
            acc.push({
              ...step,
              inactive: false,
              progress: 100,
            });

            return acc;
          }

          if (step.label === action.payload.label) {
            if (idx + 1 < state.steps.length && state.steps[idx + 1].inactive === false) {
              acc.push({
                ...step,
                progress: 100,
              });
            } else {
              acc.push({
                ...step,
                ...action.payload,
              });
            }

            return acc;
          }

          acc.push(step);
          return acc;
        }, []),
      };

    case SET_VISIBILITY:
      return {
        ...state,
        visible: action.payload,
      };

    case CLEAR_TO_INITIAL:
      return {
        ...action.payload,
      };

    case FAIL_LAST_ACTIVE_STEP:
      return {
        ...state,
        steps: state.steps.reduce((acc, step, idx) => {
          if (idx + 1 < state.steps.length) { // check all steps except last
            if (state.steps[idx + 1].inactive === false) { // if next step is active, current step can't be inactive so push it as is
              acc.push(step);
            } else if (step.inactive === false) { // next step is inactive and current active so failure was on this step - modify it
              acc.push({
                ...step,
                exception: true,
              });
            } else {
              acc.push(step);
            }
          } else if (step.inactive === false) { // check last step
            acc.push({
              ...step,
              exception: true,
            });
          } else {
            acc.push(step);
          }

          return acc;
        }, []),
      };

    default:
      return state;
  }
};

export default progressBarReducer;

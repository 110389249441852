import isEmpty from 'lodash.isempty'
import moment from 'moment'
import queryString from 'query-string'

import { USER_ROLE_IDS } from 'constant';

import history from './history'

export const formatDate = (date) => {
	return date ? moment(date).format('MMMM DD, YYYY') : ''
}

export const getToken = () => {
	const params = window.location.href.split('?')[1];
	const url = params ? `?${params}` : '';
	const user = queryString.parse(url);
	if (!isEmpty(user)) {
		setUser({ ...user, bcd: true });
		return user.sessionToken;
	} else if (localStorage.getItem('advito-user')) {
		const { accessToken, sessionToken } = JSON.parse(localStorage.getItem('advito-user'));
		return accessToken || sessionToken;
	} else return '';
};

export const getUser = () => {
	if (localStorage.getItem('advito-user')) {
		const user = JSON.parse(localStorage.getItem('advito-user'))
		return { ...user }
	} else {
		return {}
	}
}

export const isAdmin = () => {
	const user = getUser();

	return !isEmpty(user)
		?	user.roleIds.includes(USER_ROLE_IDS.ADMIN_ROLE)
		: false;
};

export const updateUserName = (displayName) => {
	if (localStorage.getItem('advito-user')) {
		const user = JSON.parse(localStorage.getItem('advito-user'))
		user.displayName = displayName
		localStorage.setItem('advito-user', JSON.stringify(user))
	}
}

export const setUser = (user) => {
	if (localStorage.getItem('advito-user')) {
		localStorage.removeItem('advito-user')
	}
	localStorage.setItem('advito-user', JSON.stringify(user))
}

export const removeUser = () => {
	const user = JSON.parse(localStorage.getItem('advito-user'))
	localStorage.removeItem('advito-user')
	if (user && user.bcd) {
		window.location.href = window.ingestionConsoleURL
	} else {
		history.push('/login')
	}
}

export const setCookie = (name, value, options = {}) => {
	options = {
		path: '/',
		...options
	}

	if (options.expires instanceof Date) {
		options.expires = options.expires.toUTCString();
	}

	let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

	for (let optionKey in options) {
		updatedCookie += "; " + optionKey;
		let optionValue = options[optionKey];
		if (optionValue !== true) {
			updatedCookie += "=" + optionValue;
		}
	}

	document.cookie = updatedCookie;
}

export const getAdministrationLink = () => {
	return window.dataManagementURL.includes('{{') // when there is no URL, only placeholder
		? null
		: window.dataManagementURL;
};

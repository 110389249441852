import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  html,
  body, #root {
    height:100%
  }
  #root {
    margin: 0;
    padding: 0;
    font-family: 'Rubik', sans-serif;
    background: ${props => props.theme.white};
    color: ${props => props.theme.doveGray};
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    
    @media (max-width : 1336px){
      font-size: 14px;
      line-height: 17px;
    }
  }
  a {
    text-decoration: none;
  }

  .ant-picker-cell {
    padding: 0;
  }

  .ant-picker-cell .ant-picker-cell-inner {
    width: 100%;
    height: 100%;
  }
  
  .ant-picker-cell-range-hover-end::after, .ant-picker-cell-range-hover::after, 
  .ant-picker-cell-range-hover-start::after {
    border: none !important;
    background: #e6f7ff;
    width: 100%;
    left: -2px;
  }
  
  .ant-picker-cell .ant-picker-cell-inner.hover-disabled {
    background: #FFCFCF !important;
    cursor: default;
  }

  .ant-picker-cell .ant-picker-cell-inner.hover-disabled::after{
    background: transparent !important;
  }

  .ant-upload.ant-upload-drag.dragger {
    height: inherit;
  }
  
  .upload-progress-bar.inactive .ant-progress-circle .ant-progress-text {
    color: #bfbfbf;
  }
  
  .upload-progress-bar .ant-progress-circle .ant-progress-text {
    color: #fff;
    font-size: 12px;
  }
  .upload-progress-bar .ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: #1890ff;
  }

  .upload-progress-bar .ant-progress-circle.ant-progress-status-exception .ant-progress-text {
    color: #ff4d4f;
  }
  
  .main-page-logo {
    max-width: 294px;
    height: auto;
  }
`

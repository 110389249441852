import React from 'react';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const { Dragger } = Upload;

const BoldText = styled.p`
  font-weight: bold;
`;

const CustomDragger = ({ disabled, dummyRequest, onFileChange, fileList, templateName }) => {
    return (
        <Dragger
            className="dragger"
            accept=".xlsx"
            disabled={disabled}
            multiple={false}
            customRequest={dummyRequest}
            showUploadList={{
                showDownloadIcon: true,
            }}
            onChange={onFileChange}
            fileList={fileList}
        >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">
                Click or drag file to this area to upload
            </p>
            {
                templateName && <BoldText>{templateName}</BoldText>
            }
        </Dragger>
    );
};

export default CustomDragger;

export default {
  alabaster: '#f9f9f9',
  white: '#ffffff',
  black: '#000000',
  pumice: '#c9ceca',
  grayNurse: '#dee1df',
  concrete: '#f4f2f2',
  doveGray: '#666666',
  silver: '#cccccc',
  tradewind: '#4baaa3',
  easternWind: '#20ABA3',
  jungleMist: '#B0D6D4',
  treePoppy: '#FF9E16',
  steelBlue: '#5890bf',
  tropicalBlue: '#cae4f9',
  deepBlush: '#EB707F',
  verticalSpace: '2.5rem',
  horizontalSpace: '3.5em'
}

import { useContext,  } from 'react';

import UploadProgressBarContext from 'components/context/progressBar/progressBarContext';
import { initState as ProgressBarState } from 'components/context/progressBar/ProgressBarState';

let interval = null;
let progress = 0;

const useWsUpdates = () => {
  const {
		clearStepProgressToInitial,
		failLastActiveStep,
		updateStepProgress,
	} = useContext(UploadProgressBarContext);

  const updateProgressBar = (jobsData) => {
    if (jobsData.step === 'Preprocessing') {
      updateStepProgress({
        ...ProgressBarState.steps[1],
        progress: 10,
        inactive: false,
      });
    }

    if (jobsData.step === 'PCI Validation') {
      progress = 0;

      updateStepProgress({
        ...ProgressBarState.steps[1],
        progress: 100,
      });
      updateStepProgress({
        ...ProgressBarState.steps[2],
        progress: 0,
        inactive: false,
      });
      interval = setInterval(() => {
        progress += 10;

        if (progress >= 100) {
          clearInterval(interval);
          interval = null;
          return;
        }

        updateStepProgress({
          ...ProgressBarState.steps[2],
          progress,
        });
      }, 1000);
    }

    if (jobsData.step === 'Data Validation') {
      if (interval) {
        clearInterval(interval);
      }
      progress = 0;

      updateStepProgress({
        ...ProgressBarState.steps[2],
        progress: 100,
      });

      updateStepProgress({
        ...ProgressBarState.steps[3],
        progress: 0,
        inactive: false,
      });

      interval = setInterval(() => {
        progress += 10;

        if (progress >= 100) {
          clearInterval(interval);
          interval = null;
          return;
        }

        updateStepProgress({
          ...ProgressBarState.steps[3],
          progress,
        });
      }, 1000);
    }

    if (jobsData.step === 'Validation Error') {
      if (interval) {
        clearInterval(interval);
      }
      progress = 0;
  
      failLastActiveStep();
    }
  
    if (jobsData.step === 'Upload Data to DB') {
      if (interval) {
        clearInterval(interval);
      }
      progress = 0;
  
      updateStepProgress({
        ...ProgressBarState.steps[3],
        progress: 100,
      });
  
      updateStepProgress({
        ...ProgressBarState.steps[4],
        progress: 0,
        inactive: false,
      });
  
      interval = setInterval(() => {
        progress += 10;
  
        if (progress >= 100) {
          clearInterval(interval);
          interval = null;
          return;
        }
  
        updateStepProgress({
          ...ProgressBarState.steps[4],
          progress,
        });
      }, 1000);
    }
  
    if (jobsData.step === 'Moving Files To Destination Folder') {
      if (interval) {
        clearInterval(interval);
      }
      progress = 0;
  
      updateStepProgress({
        ...ProgressBarState.steps[4],
        progress: 100,
      });
    }
  
    if (['Server Error', 'Date Range Validation'].includes(jobsData.step)) {
      if (interval) {
        clearInterval(interval);
      }
      progress = 0;
  
      failLastActiveStep();
    }
  };

  return { clearStepProgressToInitial, updateProgressBar };
};

export default useWsUpdates;

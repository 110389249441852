import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { useMutation } from '@apollo/client';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { LOGIN } from 'api';
import { authClient } from 'index';
import { SkeletonLoader } from 'components/common/Loader';

import { TEMPORARY_PASSWORD_EXPIRED } from './constants';
import {
  ButtonRow,
  FormContainer,
  Link,
} from './styledComponents';

const MainLoginPage = ({
  forgotPassword,
  setForgotPassword,
  setUserData,
  setError,
  setVisible,
}) => {
  const [login, { loading }] = useMutation(LOGIN, {
    client: authClient,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setUserData(data.login);
    },
    onError: (err) => {
      if (err.graphQLErrors[0].extensions.code === TEMPORARY_PASSWORD_EXPIRED) {
        setForgotPassword(false);
      }
      setError(err);
    },
  });

  const handleSubmit = ({ username, password }) => {
    setError(undefined);

    login({
      variables: { username, password }
    }).catch((e) => {
      console.error('Error in login form: ', e)
    });
  };

  return (
    <FormContainer>
      <Form onFinish={handleSubmit}>
        {loading
          ? <SkeletonLoader />
          : (
            <>
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: 'Please input your username!' },
                  {
                    type: 'email',
                    message: 'The input is not a valid email!'
                  }
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="Username" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: 'Please input your password!' }
                ]}
              >
                <Input
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
            </>
          )
        }
        <Form.Item>
          <ButtonRow>
            <Button
              type="danger"
              htmlType="submit"
              disabled={loading}
            >
              Log in
            </Button>
            <Link
              disabled={loading}
              onClick={() => setVisible(true)}
            >
              {forgotPassword ? 'Forgot Password' : 'Request new password'}
            </Link>
          </ButtonRow>
        </Form.Item>
      </Form>
    </FormContainer>
  );
}

export default MainLoginPage;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import advitoLogo from 'assets/AdvitoLogo.png';
import ErrorMessage from 'components/common/ErrorMessage';
import SuccessMessage from 'components/common/SuccessMessage';
import { setUser } from 'helper';

import Footer from './Footer';
import ResetPasswordModal from './ResetPasswordModal';
import {
	Container,
	Logo,
	Title,
} from './styledComponents';
import { CHALLENGE } from './constants';
import MainLoginPage from './MainLoginPage';
import ResetPassword from './ResetPassword';
import MFA from './MFA';

const mfaPages = [CHALLENGE.MFA_SETUP, CHALLENGE.SOFTWARE_TOKEN_MFA, CHALLENGE.EMAIL_TOKEN_MFA];

const Login = () => {
	const [visible, setVisible] = useState(false);
	const [userData, setUserData] = useState();
	const [error, setError] = useState();
	const [forgotPassword, setForgotPassword] = useState(true);
	const history = useHistory();

	useEffect(() => {
		const advitoUser = localStorage.getItem('advito-user');

		if (!advitoUser) return;

		const { accessToken, sessionToken } = JSON.parse(advitoUser);

		if (accessToken || sessionToken) {
			history.push('/');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (userData?.id) {
			setUser(userData);

			history.push('/')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData]);

	if (userData?.ChallengeName === CHALLENGE.NEW_PASSWORD_REQUIRED
		|| userData?.forgotPassword) {
		return <ResetPassword userData={userData} setUserData={setUserData} />;
	}

	return (
		<Container>
			<Logo>
				<img src={advitoLogo} alt="logo" />
			</Logo>
			<Title>
				Welcome to the <br /> Advito Ingestion Console
			</Title>
			{mfaPages.includes(userData?.ChallengeName)
				&& <MFA userData={userData} setUserData={setUserData} setError={setError} />}
			{!userData?.ChallengeName && (
				<MainLoginPage
                    forgotPassword={forgotPassword}
                    setForgotPassword={setForgotPassword}
                    setVisible={setVisible}
                    setUserData={setUserData}
                    setError={setError}
                />
			)}

			{userData?.id && <SuccessMessage message={'Success!'} />}
			{error && <ErrorMessage error={error} />}

			<ResetPasswordModal
              forgotPassword={forgotPassword}
              visible={visible}
              setVisible={setVisible}
              setUserData={setUserData}
            />
			<Footer />
		</Container>
	);
}

export default Login

import React, { useState, useEffect, useRef } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Alert, DatePicker } from 'antd';

import {
	DATE_RANGES_LIST,
	PRACTICE_AREA_LIST,
	TEMPLATE_LIST
} from 'api/queries';
import { Title } from 'components/common/Typography';
import { SpinLoader } from 'components/common/Loader';
import { APPLICATIONS, PRACTICE_AREAS } from 'constant';
import { getToken, getUser } from 'helper';

import FileUpload, { initialFilesList } from './FileUpload';
import SelectPractice from './SelectPractice';
import SelectClient from './SelectClient';
import SelectTemplate from './SelectTemplate';
import SelectSource from './SelectSource';
import JobProgress from './JobProgress';
import JobProgress360 from './JobProgress360';
import {
	getDatePickerCells,
	getDisabledDates,
	getMomentRangeDates,
	isBatchNeeded,
	monthCellRender,
} from './helper';
import useWsUpdates from './hooks/useWsUpdates';

const { RangePicker } = DatePicker;

const Container = styled.div`
	width: 900px;
	padding-bottom: ${(props) => props.theme.verticalSpace};
`;

const StyledTitle = styled(Title)`
	margin-bottom: ${(props) => props.theme.verticalSpace};
`;

const Row = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.theme.verticalSpace};
	justify-content: space-between;
`;

const PickerRow = styled(Row)`
	max-width: 400px;
`;

const DateSelect = styled.div`
	display: flex;
	flex-direction: column;

	> span {
		text-transform: uppercase;
		margin-bottom: 2px;
		font-size: 0.85em;
	}
`;

const MessageHeading = styled.div`
	font-weight: 400;
	font-size: 1.25em;
`;

const Message = styled(Alert)`
	margin-top: 25px;
`;

const wsConnectionStates = [0, 1];
const SUCCESS_COMPLETED = 'Moving Files To Destination Folder';
const ERROR_COMPLETED = [
	'Validation Error',
	'Date Range Validation',
	'Server Error',
];
const COMPLETED = [
	SUCCESS_COMPLETED,
	...ERROR_COMPLETED
];
let outerJobs = [];

const Form = () => {
	const { clearStepProgressToInitial, updateProgressBar } = useWsUpdates();
	const [inputs, setInputs] = useState({
		// application: 1,
		// client: 348,
		// fileEndDate: '2020-03-11',
		// fileStartDate: '2020-03-11',
		// source: 16,
		// template: 4,
		client: null,
		application: null,
		template: null,
		source: null,
		fileStartDate: null,
		fileEndDate: null,
	});
	const [job, setJob] = useState(null);
	// const [job, setJob] = useState({
	// 	id: 1,
	// 	jobStatus: 'completed',
	// 	isComplete: true,
	// });
	const [jobs, setJobs] = useState([]);
	const [message, setMessage] = useState({});
	const [dateRanges, setDateRanges] = useState([]);
	const [disabledClickCells, setDisabledClickCells] = useState({});
	const [period, setPeriod] = useState([null, null]);
	const [sources, setSources] = useState([]);
	const [fileList, setFile] = useState(initialFilesList);
	const [needFetchDateRanges, setNeedFetchDateRanges] = useState(true);
	const [needDisable, setNeedDisable] = useState(true);
	const ws = useRef();
	outerJobs = jobs;
	const user = getUser();
	const token = getToken();

	useEffect(() => {
		if (window.webSocketURL === '{{web-socket-url}}') return;
		if ((!ws.current || !wsConnectionStates.includes(ws.current.readyState)) && Object.keys(user).length) {
			ws.current = new WebSocket(`${window.webSocketURL}?sessiontoken=${encodeURIComponent(token)}`);

			ws.current.onopen = () => {
				setInterval(() => {
					ws.current.send('ping');
				}, 9.5 * 60 * 1000) // 9.5 minutes
			};

			ws.current.onmessage = ({ data }) => {
				try {
					const jobsData = JSON.parse(data);
					updateProgressBar(jobsData);

					// if validation error arrives earlier then validation in progress message
					if (['server_error', 'error'].includes(outerJobs[0].jobStatus)) {
						return;
					}

					const jobExists = outerJobs.findIndex((job) => job.id === jobsData.jobID) !== -1;

					jobExists &&
						setJobs(outerJobs.map((job) => {
							let jobRes = {};
							if (jobsData.step === ERROR_COMPLETED[0]) {
								jobRes = jobsData.templates.find((jobData) => jobData.errors.jobID === job.id);
							}

							if ([ERROR_COMPLETED[1], ERROR_COMPLETED[2], SUCCESS_COMPLETED].includes(jobsData.step)) {
								jobRes = jobsData.templates.find((jobData) => jobData.jobID === job.id);
							}

							return {
								...job,
								...jobRes,
								...(SUCCESS_COMPLETED === jobsData.step && {
									jobStatus: jobRes?.jobStatus || 'completed',
									quarantineCountRows: jobRes?.quarantineCountRows,
								}),
								...(ERROR_COMPLETED.includes(jobsData.step) && { jobStatus: 'error' }),
								...(ERROR_COMPLETED[2] === jobsData.step && { jobStatus: 'server_error' }),
								isComplete: COMPLETED.includes(jobsData.step),
							};
						}));
				} catch (e) {}
			};
		}
	}, [user, token, job]);

	const { data, client, error } = useQuery(PRACTICE_AREA_LIST, {
		variables: { clientId: inputs.client },
		fetchPolicy: 'network-only',
	});

	const [fetchDateRanges, { loading }] = useLazyQuery(DATE_RANGES_LIST, {
		variables: {
			clientId: inputs.client,
			source: inputs.source,
		},
		fetchPolicy: 'network-only',
		onCompleted: (results) => {
			setDateRanges(getMomentRangeDates(results.dateRanges));
		}
	});

	const getMailTo = () => {
		const user = JSON.parse(localStorage.getItem('advito-user')).displayName;

		if (!error) {
			const applicationName = data.practiceAreaList.find(
				(app) => app.id === inputs.application
			).applicationName;

			const { templateList } = client.readQuery({
				query: TEMPLATE_LIST,
				variables: {
					applicationId: inputs.application,
				},
			});

			const templateName = templateList.find(
				(template) => template.id === inputs.template
			).templateName;

			return `mailto:AdvitoServices@bcdtravel.eu?subject= Advito I%26A Ingestion Console Assistance Request/Source Not Listed
        &body=Please provide a detailed description of the missing data source so that we can provide prompt assistance.%0D%0A
        %0D%0A
        Username: ${user}
        %0D%0A
        Practice Area Selection: ${applicationName || 'undefined'}
        %0D%0A
        Template: ${templateName || 'undefined'}
        %0D%0A
        `;
		} else
			return `mailto:AdvitoServices@bcdtravel.eu?subject= Advito I%26A Ingestion Console Assistance Request/Source Not Listed
    &body=Please provide a detailed description of the missing data source so that we can provide prompt assistance.%0D%0A
    %0D%0A
    Username: ${user}
    %0D%0A
    Practice Area Selection: ${'undefined'}
    %0D%0A
    Template: ${'undefined'}
    %0D%0A
    `;
	};

	const handleInputChange = (key, value) => {
		if (job) {
			setJobs([]);
			setMessage({});
			clearStepProgressToInitial();
		}

		if (key === 'client') {
			setInputs((inputs) => ({
				...inputs,
				[key]: value,
				application: null,
				template: null,
				source: null,
			}));
			setMessage({});
		} else if (key === 'application') {
			setInputs((inputs) => ({
				...inputs,
				[key]: value,
				template: null,
				source: null,
			}));
			setMessage({});
		} else if (key === 'template') {
			setInputs((inputs) => ({
				...inputs,
				[key]: value,
				source: null,
			}));
			setMessage({});
		} else {
			setInputs((inputs) => ({
				...inputs,
				[key]: value,
			}));
			needFetchDateRanges && fetchDateRanges();
			if (value === 0) {
				setMessage({
					message: (
						<>
							<MessageHeading>Not seeing what you need?</MessageHeading>
							<div>
								<a href={getMailTo()}>Contact I&amp;A</a> to add your source
								selection.
							</div>
						</>
					),
					type: 'error',
				});
			} else {
				setMessage({});
			}
		}
	}

	const handleDateChange = (date, dateString) => {
		if (job && inputs.fileStartDate !== null && inputs.fileStartDate !== dateString[0]) {
			setJobs([]);
			setMessage({});
		}

		if (job && inputs.fileEndDate !== null && inputs.fileEndDate !== dateString[1]) {
			setJobs([]);
			setMessage({});
		}

		setInputs((inputs) => ({
			...inputs,
			fileStartDate: dateString[0],
			fileEndDate: dateString[1],
		}));
	}

	const isBatchUpload = isBatchNeeded(inputs.application, inputs.template);

	return (
		<Container>
			<StyledTitle>Ingestion Console</StyledTitle>
			<Row>
				<SelectClient
					label="Upload for Client"
					onChange={(e) => handleInputChange('client', e)}
				/>
				<SelectPractice
					label="Practice Area"
					variables={{ clientId: inputs.client }}
					onChange={(e, item) => {
						setNeedFetchDateRanges(![PRACTICE_AREAS.AIR, PRACTICE_AREAS.HOTEL].includes(item.children));
						handleInputChange('application', e)
					}}
				/>
			</Row>
			<Row>
				<SelectTemplate
					label="Template"
					variables={{ applicationId: inputs.application }}
					onChange={(e) => handleInputChange('template', e)}
				/>
				<SelectSource
					label="Source"
					variables={{ templateId: inputs.template }}
					application={inputs.application}
					onChange={(e) => handleInputChange('source', e)}
					setSources={setSources}
				/>
			</Row>
			<PickerRow>
				{
					loading
						? <SpinLoader />
						: <DateSelect>
							<span>File Date Range</span>
							<RangePicker
								disabled={!inputs.source}
								onChange={handleDateChange}
								placeholder={['Start Date', 'End Date']}
								style={{ width: '400px' }}
								disabledDate={(current) => getDisabledDates(current, dateRanges, needDisable)}
								onCalendarChange={(value) => setPeriod(value)}
								dateRender={(current, today, info) =>
									getDatePickerCells(
										current,
										today,
										info,
										dateRanges,
										period,
										disabledClickCells,
										setDisabledClickCells,
										setNeedDisable,
									)}
								monthCellRender={(date) => monthCellRender(date, setNeedDisable)}
							/>
						</DateSelect>
				}
			</PickerRow>
			<FileUpload
				disabled={Object.values(inputs).some((v) => v === null || v === 0)}
				inputs={inputs}
				setMessage={setMessage}
				setJob={setJob}
				job={job}
				isBatchUpload={isBatchUpload}
				sources={sources}
				jobs={jobs}
				setJobs={setJobs}
				fileList={fileList}
				setFile={setFile}
			/>
			{job &&
			job.id &&
			(inputs.application === APPLICATIONS.ANALYTICS_360 || inputs.application === APPLICATIONS.AIR ?
				(
					<JobProgress360
						jobs={jobs}
						MessageHeading={MessageHeading}
						setParentMessage={setMessage}
					/>
				) :
				(
					<JobProgress
						job={job}
						MessageHeading={MessageHeading}
						setParentMessage={setMessage}
					/>
				))
			}
			{message.message && (
				<Message message={message.message} type={message.type} showIcon />
			)}
		</Container>
	);
};

export default Form;
export { Message };

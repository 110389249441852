import React from 'react';
import styled from 'styled-components'
import { Tag } from 'antd';

export const FormSelect = styled.div`
  display: flex;
  flex-direction: column;
  width: 440px;
  > span {
    text-transform: uppercase;
    margin-bottom: 2px;
    font-size: 0.85em;
  }
`;

export const Heading = styled.p`
  font-size: 1rem;
  font-weight: 500;
  line-height: 150%;
  color: #000;
  margin-bottom: 1.25rem;
`;

const BaseTag = styled(Tag)`
  padding: 0 0.5rem;
  color: #fff;
  font-size: 0.75rem;
  border: none;
  border-radius: 3rem;
  margin: 0;
`;

export const SuccessTag = styled(BaseTag)`
  background-color: #52C41A;
`;

export const ErrorTag = styled(BaseTag)`
  background-color: #FF4D4F;
`;

export const QuarantineTag = styled(BaseTag)`
  background-color: #FAAD14;
;
`;

export const ErrorsContainer = styled.ul`
  margin: 0;
  padding-left: 1rem;
  > li {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

export const ValidationText = styled.p`
  font-size: 0.875rem;
  color: #000;
  line-height: 157%;
  margin-bottom: 0.75rem;
`;

export const ErrorCollapseContainer = styled.div`
  margin-bottom: 1rem;
`;

export const DownloadErrorReport = styled.a`
  margin-left: 1rem;
`;

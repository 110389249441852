import gql from 'graphql-tag';

const loginResult = `
  id
  displayName
  clientId
  sessionToken
  roleIds
  accessToken
  refreshToken
  ChallengeName
  Session
  ChallengeParameters {
    FRIENDLY_DEVICE_NAME
    MFAS_CAN_SETUP
    USER_ID_FOR_SRP
  }
`;

export const SEND_RESET_PASSWORD = gql`
	mutation SendResetPasswordEmail($email: String!, $recreate: Boolean) {
		sendResetPasswordEmail(email: $email, recreate: $recreate)
	}
`;

export const LOGIN = gql`
	mutation Login($username: String!, $password: String!) {
		login(username: $username, password: $password) {
			${loginResult}
		}
	}
`;

export const LOGOUT = gql`
	mutation Logout($sessionToken: String!) {
		logout(sessionToken: $sessionToken)
	}
`;

export const RESET_PASSWORD = gql`
	mutation ResetPassword(
		$token: String!
		$password: String!
		$confirmPassword: String!
		$cognitoId: String
		$session: String
		$email: String
		$confirmationCode: String
	) {
		resetPassword(
			token: $token
			password: $password
			confirmPassword: $confirmPassword
			cognitoId: $cognitoId
			session: $session
			email: $email
			confirmationCode: $confirmationCode
		) {
		  ${loginResult}
		}
	}
`;

export const MFA_VERIFY = gql`
  mutation MfaVerify(
    $username: String!
    $mfa: String!
    $session: String!
    $challengeName: String
  ) {
    mfaVerify(
      username: $username
      mfa: $mfa
      session: $session
      challengeName: $challengeName
    ) {
      ${loginResult}
    }
  }
`;

export const ASSOCIATE_MFA_TOKEN = gql`
  mutation AssociateMfaToken($session: String!) {
    associateMfaToken(session: $session) {
      SecretCode
      Session
    }
  }
`;

export const MFA_SETUP = gql`
  mutation MfaSetup($username: String!, $code: String!, $session: String!) {
    mfaSetup(username: $username, code: $code, session: $session) {
      ${loginResult}
    }
  }
`;

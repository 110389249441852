import React, { useState } from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';
import pluralize from 'pluralize';
import { useLazyQuery } from '@apollo/client';

import { DOWNLOAD_ERROR_FILE } from 'api';

import { getErrorText360 } from './helper';
import { Message } from './';
import {
	DownloadErrorReport,
	ErrorsContainer,
	ErrorCollapseContainer,
	ErrorTag,
	ValidationText,
	Heading,
	SuccessTag,
	QuarantineTag,
} from './StyledComponents';

const Wrapper = styled.div`
	margin-top: 48px;
`;

const ErrorCount = styled.div`
	font-weight: bold;
	font-size: 1.3em;
`;

const JobProgress360 = ({ MessageHeading, jobs, setParentMessage }) => {
	const [message, setMessage] = useState({})
	// const [doneErrorRequest, setDoneErrorRequest] = useState(false)
	// const [errorOffset, setErrorOffset] = useState(0)
	// const [prevErrorOffset, setPrevErrorOffset] = useState(0)
	// const [showMoreErrors] = useLazyQuery(SHOW_MORE_ERRORS, {
	// 	onCompleted: ({ showMoreErrors }) => {
	// 		setJob({
	// 			...job,
	// 			errors: {
	// 				...job.errors,
	// 				errors: showMoreErrors
	// 			}
	// 		})
	// 	}
	// })
	const [downloadErrorFile] = useLazyQuery(DOWNLOAD_ERROR_FILE, {
		onCompleted: ({ downloadErrorFile }) => {
			const element = document.createElement('a')
			element.setAttribute('href', downloadErrorFile.url)
			element.setAttribute('download', downloadErrorFile.key)
			element.target = '_blank'
			element.click()
		},
		onError: (err) => {
			setParentMessage({
				message: (
					<MessageHeading>{err.message}</MessageHeading>
				),
				type: 'error'
			})
		}
	});

	const onErrorsDownload = (jobs) => {
		const links = jobs.reduce((jobAcc, job) => {
			if (job.errors) {
				jobAcc.push({
					bucket: job.errors.link.bucket,
					key: job.errors.link.key,
					jobId: job.errors.link.job_id,
				});
			}
			return jobAcc;
		}, []);

		downloadErrorFile({
			variables: { links },
		});
	}

	const isDateRangeError = jobs.find((job) => job.errors?.type === 'daterange');
	const isServerError = jobs.find((job) => job.jobStatus === 'server_error');
	const isQuarantined = jobs.find((job) => job.jobStatus === 'quarantine');

	if (jobs[0]?.isComplete) {
		if (isDateRangeError) {
			(!message.message && setMessage({
				message: getErrorText360(isDateRangeError.errors),
				type: 'error',
			}));
		}

		if (isServerError) {
			const getMailTo = () => {
				const user = JSON.parse(localStorage.getItem('advito-user')).displayName

				return `mailto:AdvitoServices@bcdtravel.eu?subject= Advito I%26A Ingestion Console Assistance Request
				&body=Please provide a detailed description of your need so that we can provide prompt assistance.%0D%0A
				%0D%0A
				Username: ${user}
				%0D%0A
				Filenames: ${jobs.map((job) => job.originalFileName).join(', ')}
				%0D%0A
				Practice Area Selection: ${isServerError.applicationName}
				%0D%0A
				Templates: ${jobs.map((job) => job.templateName).join(', ')}
				%0D%0A
				Date/time of ingestion attempt: ${jobs.map((job) => new Date(+job.processingStartTimestamp)).join(', ')}
				%0D%0A
				`
			};

			!message.message && setMessage({
				message: (
					<>
						<ErrorCount>There was a server error. We will contact you by e-mail as soon as we solve the issue.</ErrorCount>
						<div>
							Tried everything and still getting errors?{' '}
							<a target="_blank" href={getMailTo()}>Contact I&amp;A</a> within 5 days of upload
							for assistance.
						</div>
					</>
				),
				type: 'error',
			});
		}
	}

	return (
		<React.Fragment>
			{jobs[0]?.isComplete && !isDateRangeError && !isServerError && (
				<Wrapper>
					<Heading>Results</Heading>
					{jobs[0].jobStatus === 'error' && (
						<ValidationText>
							Your file will not be ingested due to the following errors.
							<DownloadErrorReport
								href="javascript:void(0);"
								onClick={() => onErrorsDownload(jobs)}
							>
								Download error report
							</DownloadErrorReport>
						</ValidationText>
					)}
					{jobs[0].jobStatus === 'completed' && (
						<ValidationText>Files have been successfully checked.</ValidationText>
					)}
					{!!isQuarantined && (
						<ValidationText>Files have been uploaded with errors.</ValidationText>
					)}
					<ErrorCollapseContainer>
						<Collapse>
							{jobs.map((job) => (
								<Collapse.Panel
									header={job.originalFileName}
									key={job.id}
									extra={
										job.errors?.errorsCount
											? <ErrorTag>{pluralize('error', job.errors.errorsCount, true)}</ErrorTag>
											: job.quarantineCountRows
												? <QuarantineTag>{job.quarantineCountRows} quarantined</QuarantineTag>
												: <SuccessTag>0 errors</SuccessTag>}
								>
									<ErrorsContainer>
										{job.errors?.errors.map(getErrorText360) || (job.quarantineCountRows
											? <li>File has been uploaded with errors!</li>
											: <li>File has been successfully checked ({job.countRows} rows checked)</li>
										)}
									</ErrorsContainer>
								</Collapse.Panel>
							))}
						</Collapse>
					</ErrorCollapseContainer>
					{jobs[0].jobStatus === 'error' && (
						<ValidationText>Please resolve the errors and try uploading again.</ValidationText>
					)}
					{(jobs[0].jobStatus === 'completed' || isQuarantined) && (
						<ValidationText>Files will be loaded into our database in a few minutes (you can always check its processing status in Data Management).</ValidationText>
					)}
				</Wrapper>
			)}
			{message.message && (
				<Message message={message.message} type={message.type} showIcon />
			)}
		</React.Fragment>
	)
}

export default JobProgress360

import gql from 'graphql-tag'

export const UPLOAD_FILE = gql`
	mutation uploadFile(
		$clientId: Int!
		$advitoApplicationId: Int!
		$sourceId: Int!
		$templateId: Int!
		$dataStartDate: Date!
		$dataEndDate: Date!
		$fileName: String!
		$jobStatus: String!
		$fileSize: Int!
		$key: String!
		$countRows: Int!
	) {
		uploadFile(
			clientId: $clientId
			advitoApplicationId: $advitoApplicationId
			sourceId: $sourceId
			templateId: $templateId
			dataStartDate: $dataStartDate
			dataEndDate: $dataEndDate
			fileName: $fileName
			jobStatus: $jobStatus
			fileSize: $fileSize
			key: $key
			countRows: $countRows
		) {
			id
			originalFileName
			processingStartTimestamp
			jobName
			jobStatus
			templateName
			applicationName
			countRows
		}
	}
`

export const UPDATE_JOB = gql`
    mutation UpdateJob(
        $jobId: ID!
        $key: String!
		$jobStatus: String!
        $batchId: ID
    ) {
        updateJob(
            jobId: $jobId
            key: $key
			jobStatus: $jobStatus
            batchId: $batchId
        )
    }
`;

export const BATCH_UPLOAD = gql`
    mutation BatchUpload($templateId: ID!) {
        batchUpload(templateId: $templateId) {
            batchId
        }
    }
`;

export const BATCH_RUN = gql`
    mutation BatchRun($records: [BatchUploadInput]!) {
        batchRun(records: $records)
    }
`;

import gql from 'graphql-tag';

export const CLIENT_LIST = gql`
	{
		clientList {
			id
			clientName
		}
	}
`;

export const PRACTICE_AREA_LIST = gql`
	query practiceAreaList($clientId: Int) {
		practiceAreaList(clientId: $clientId) {
			id
			applicationName
		}
	}
`;

export const TEMPLATE_LIST = gql`
	query templateList($applicationId: Int) {
		templateList(applicationId: $applicationId) {
			id
			templateName
		}
	}
`;

export const SOURCE_LIST = gql`
	query SourceList($templateIds: [Int]!) {
		sourceList(templateIds: $templateIds) {
			id
			sourceName
		}
	}
`;

export const APPLICATION_LIST = gql`
	{
		applicationList {
			id
			applicationName
		}
	}
`;

export const SAMPLE_TEMPLATE_LIST = gql`
	{
		sampleTemplateList {
			id
			templateName
			applicationName
			templatePath
		}
	}
`;

export const DATE_RANGES_LIST = gql`
	query DateRanges($clientId: ID, $source: Int) {
		dateRanges(clientId: $clientId, source: $source) {
			id
			dataStartDate
			dataEndDate
		}
	}
`;

export const ANALYTICS_ID = 3;

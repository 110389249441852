import React, { useContext, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

import {APPLICATIONS, TEMPLATE_NAMES} from 'constant';
import UploadProgressBarContext from 'components/context/progressBar/progressBarContext';

import UploadConfirmation from './UploadConfirmation';
import CustomDragger from './CustomDragger';
import UploadProgressBar from './UploadProgressBar';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: ${(props) => props.theme.verticalSpace};
`;

const UploadButton = styled(Button)`
	margin-top: 2.5px;
	align-self: center;
	width: 100px;
`;

const DraggerWrapper = styled.div`
	display: flex;
	
	> span {
		width: 100%;
	}
	
	> span:nth-child(2) {
		margin-left: 20px;
	}
`;

export const initialFilesList = {
	ticket: [],
	segment: [],
	other: [],
};

const FileUpload = ({
	inputs,
	disabled,
	setMessage,
	setJob,
	job,
	isBatchUpload,
	sources,
	jobs,
	setJobs,
	fileList,
	setFile,
}) => {
	const uploadEnabled = useMemo(() => Object.entries(inputs)
		.reduce((acc, [, item]) => {
			return acc && !!item;
		}, true), [inputs]);

	const [modal, setModal] = useState(false);
	const [parsing, setParsing] = useState(false);
	const [parsingError, setParsingError] = useState('');
	const { visible: isProgressBarVisible, clearStepProgressToInitial } = useContext(UploadProgressBarContext);

	useEffect(() => {
		if (inputs.source === 0 &&
			(fileList.ticket.length || fileList.segment.length || fileList.other.length)) {
			setFile(initialFilesList);
		}
	}, [inputs.source]);

	useEffect(() => {
		if ((!inputs.client || !inputs.application || !inputs.template) &&
			(fileList.ticket.length || fileList.segment.length || fileList.other.length)) {
			setFile(initialFilesList);
		}
	}, [inputs.client, inputs.application]);

	const dummyRequest = ({ onSuccess }) => {
		setTimeout(() => {
			onSuccess(null)
		}, 0)
	}

	const toggleModal = () => {
		setModal(!modal);
	}

	const onFileChange = async (templateName, info) => {
		if (job) {
			setJob(null);
			setMessage({});
			setJobs([]);
			clearStepProgressToInitial();
		}

		if (info.file.status === 'removed') {
			setFile({ ...fileList, [templateName]: [] })
			setMessage({});
			clearStepProgressToInitial();
		} else {
			info.file.status = 'done';
			setFile({...fileList, [templateName]: [info.file]});
		}
	}

	const filesListOrNull = {
		ticket: fileList.ticket.length ? fileList.ticket[0].originFileObj : null,
		segment: fileList.segment.length ? fileList.segment[0].originFileObj : null,
		other: fileList.other.length ? fileList.other[0].originFileObj : null,
	};

	return (
		<>
			<Container>
				{
					isBatchUpload ?
						<DraggerWrapper>
							<CustomDragger
								disabled={disabled || parsing}
								dummyRequest={dummyRequest}
								onFileChange={(info) => onFileChange('ticket', info)}
								fileList={fileList.ticket}
								templateName={TEMPLATE_NAMES.TICKET}
							/>
							<CustomDragger
								disabled={disabled || parsing}
								dummyRequest={dummyRequest}
								onFileChange={(info) => onFileChange('segment', info)}
								fileList={fileList.segment}
								templateName={TEMPLATE_NAMES.SEGMENT}
							/>
						</DraggerWrapper>
						:
						<CustomDragger
							disabled={disabled || parsing}
							dummyRequest={dummyRequest}
							onFileChange={(info) => onFileChange('other', info)}
							fileList={fileList.other}
						/>
				}
				<span
					style={{ fontSize: '.7em', alignSelf: 'center', marginTop: '20px' }}
				>
					Max file size: 30 Mb
				</span>
				<UploadButton
					type="primary"
					onClick={toggleModal}
					disabled={
						navigator.userAgent.indexOf('MSIE') !== -1 ||
						navigator.appVersion.indexOf('Trident/') > -1 ||
						navigator.userAgent.indexOf('Edge') !== -1 ||
						!uploadEnabled || parsing || parsingError ||
						(isBatchUpload
							? !(fileList.ticket.length && fileList.segment.length)
							: !fileList.other.length)
					}
				>
					Upload
				</UploadButton>
			</Container>
			{isProgressBarVisible
				&& (inputs.application === APPLICATIONS.ANALYTICS_360 || inputs.application === APPLICATIONS.AIR)
				&& <UploadProgressBar/>}
			<UploadConfirmation
				visible={modal}
				file={fileList.other.length ? fileList.other[0].originFileObj : null}
				inputs={inputs}
				setParsing={setParsing}
				parsingError={parsingError}
				setParsingError={setParsingError}
				setJob={setJob}
				jobs={jobs}
				setJobs={setJobs}
				setFileList={setFile}
				setParentMessage={setMessage}
				toggleModal={toggleModal}
				isBatchUpload={isBatchUpload}
				filesList={filesListOrNull}
				initialFilesList={initialFilesList}
				sources={sources}
			/>
		</>
	);
}

export default FileUpload;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import styled from 'styled-components';

import ProgressBarContext from 'components/context/progressBar/progressBarContext';

const ProgressBarItemContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
`;

const ProgressWrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 100;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
`;

const Background = styled.div`
  position: absolute;
  background: #1890ff;
  top: 4px;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  z-index: 10;
`;

const Label = styled.span`
  color: ${(props) => props.$inactive && !props.$finished ? '#8c8c8c' : props.$exception ? '#ff4d4f' : '#000'};
  color: ${(props) => props.$exception ? '#ff4d4f' : props.$inactive && !props.$finished ? '#8c8c8c' : '#000'};
  margin-left: 4px;
`;

const Header = styled.h6`
  color: #000;
  font-size: 16px;
  margin-bottom: 20px;
`;

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StepsDivider = styled.div`
  width: 20px;
  height: 1px;
  background: ${(props) => props.$inactive ? '#F0F0F0' : '#1890ff'};
`;

const ProgressBarItem = ({ step, progress, label, inactive, exception }) => {
  const finished = progress === 100;
  const rest = {
    ...(!finished && !exception && { format: () => step }),
    ...((finished || inactive || exception) && { strokeWidth: 4 }),
    ...(inactive && { trailColor: '#bfbfbf' }),
    ...(exception && { status: 'exception' }),
    ...(!exception && { strokeColor: '#1890ff' }),
  };
  const progressBarClass = `upload-progress-bar ${inactive ? 'inactive' : ''}`;

  return (
    <ProgressBarItemContainer className={progressBarClass}>
      <ProgressWrapper>
        <Progress
          type="circle"
          percent={exception ? 100 : progress}
          width={(inactive || exception || finished) ? 24 : 32}
          {...rest}
        />
      </ProgressWrapper>
      {!finished && !inactive && !exception && <Background />}
      <Label
        $inactive={inactive}
        $exception={exception}
        $finished={finished}
      >
        {label}
      </Label>
    </ProgressBarItemContainer>
  );
};

const UploadProgressBar = () => {
  const { steps } = useContext(ProgressBarContext);

  return (
    <>
      <Header>Ingestion procedure</Header>
      <StepContainer>
        {steps.map((step, idx) => (
          <React.Fragment key={step.label}>
            <ProgressBarItem
              step={idx + 1}
              progress={step.progress}
              label={step.label}
              inactive={step.inactive}
              exception={step.exception}
            />
            {steps.length - 1 !== idx && (
              <StepsDivider
                $inactive={
                  (step.inactive === undefined && steps[idx + 1].inactive === undefined) ||
                  (step.inactive === false && steps[idx + 1].inactive === undefined) ||
                  (step.inactive === false && steps[idx + 1].inactive === false && steps[idx + 1].progress !== 100) ||
                  steps[idx + 1].exception
                }
              />
            )}
          </React.Fragment>
        ))}
      </StepContainer>
    </>
  );
};

ProgressBarItem.propTypes = {
  step: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  exception: PropTypes.bool,
  inactive: PropTypes.bool,
  progress: PropTypes.number,
};
ProgressBarItem.defaultProps = {
  progress: 0,
  exception: false,
  inactive: true,
};

export default UploadProgressBar;

import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Modal, Spin } from 'antd';

import { CLIENT_LIST, GET_PRESIGNED_UPLOAD_URL } from 'api/queries';
import { UPLOAD_FILE, UPDATE_JOB, BATCH_UPLOAD, BATCH_RUN } from 'api/mutations';
import UploadProgressBarContext from 'components/context/progressBar/progressBarContext';
import { initState as ProgressBarState } from 'components/context/progressBar/ProgressBarState';
import { SpinLoader } from 'components/common/Loader';
import ErrorMessage from 'components/common/ErrorMessage';
import { client, workerInstance } from 'index';
import { APPLICATIONS, MSG_TYPES, TEMPLATES } from 'constant';

import { getCurrentSource } from './helper';

const UploadConfirmation = ({
	visible,
	inputs,
	toggleModal,
	setJob,
	setFileList,
	setParentMessage,
	setParsing,
	parsingError,
	setParsingError,
	isBatchUpload,
	filesList,
	initialFilesList,
	sources,
	jobs,
	setJobs,
}) => {
	const { loading, error, data } = useQuery(CLIENT_LIST);
	const [signedUrl, updateSignedUrl] = useState({ key: '', url: '' });
	const [message, setMessage] = useState('');
	const [countRows, setCountRows] = useState({
		ticket: null, segment: null, other: null
	});
	const { updateStepProgress, setVisibilityProgressBar } = useContext(UploadProgressBarContext);

	const isFilesSelected = isBatchUpload ? (filesList.ticket && filesList.segment) : filesList.other;

	const [uploadFileMutation] = useMutation(UPLOAD_FILE, {
		onCompleted: ({ uploadFile }) => {
			setJob({
				...uploadFile,
			});
			setJobs([...jobs, uploadFile]);
			setFileList(initialFilesList);
			setParentMessage({});
		},
	});

	const [updateJob] = useMutation(UPDATE_JOB);
	const [batchUpload] = useMutation(BATCH_UPLOAD);
	const [batchRun] = useMutation(BATCH_RUN);

	const uploadFileAndGetPresignedUrl = async (file, fileSize, template) => {
		const sourceId = template === 'other' ?
			inputs.source :
			getCurrentSource(template, inputs, sources);

		const job = await uploadFileMutation({
			variables: {
				clientId: inputs.client,
				advitoApplicationId: inputs.application,
				sourceId,
				templateId: inputs.template,
				dataStartDate: inputs.fileStartDate,
				dataEndDate: inputs.fileEndDate,
				fileName: file.name,
				jobStatus: 'uploading',
				fileSize,
				key: '',
				countRows: countRows[template],
			},
		});
		const jobId = job?.data?.uploadFile?.id;

		const result = await client.query({
			query: GET_PRESIGNED_UPLOAD_URL,
			variables: {
				fileName: file.name,
				applicationId: inputs.application,
				jobId
			},
			fetchPolicy: 'network-only',
			onError: console.error
		});

		const { key, url } = result.data.getPresignedUploadUrl;
		return { jobId, key, url };
	};

	const updateJobAndFile = async (
		jobId,
		fileKey,
		fileUrl,
		file,
		batchId,
		filesSizes, // sizes of 2 files in case of Advanced template was chosen
		uploadedSize, // size of first uploaded file (ticket)
	) => {
		await new Promise((resolve, reject) => {
			const errorHandler = () => reject();
			const progressHandler = (e) => {
				const total = filesSizes || e.total; // use filesSizes for Advanced template or e.total size when file is one
				updateStepProgress({
					...ProgressBarState.steps[0],
					progress: ((uploadedSize || 0) + e.loaded) / total * 100, // calculate total progress based on two or one files
				});

				if (e.loaded === e.total) {
					resolve();
				}
			};

			const xhr = new XMLHttpRequest();
			xhr.upload.addEventListener('progress', progressHandler, false);
			xhr.addEventListener('error', errorHandler, false);
			xhr.addEventListener('abort', errorHandler, false);
			xhr.open('PUT', fileUrl);
			xhr.setRequestHeader('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
			xhr.send(file);
		});

		await updateJob({
			variables: {
				key: fileKey,
				jobId,
				jobStatus: 'planned',
				batchId,
			}
		});
	};

	const handleFileUpload = async ({ key, url }, isFilesSelected) => {
		try {
			toggleModal();

			if (!isFilesSelected) return;

			const fileSize = filesList.other?.size;

			if (inputs.application === APPLICATIONS.ANALYTICS_360 || inputs.application === APPLICATIONS.AIR) {
				setVisibilityProgressBar(true);
				updateStepProgress({
					...ProgressBarState.steps[0],
					inactive: false,
					progress: 0,
				});
				if (isBatchUpload) {
					const ticketFileSize = filesList.ticket.size;
					const segmentFileSize = filesList.segment.size;

					const {
						jobId: ticketJobId,
						key: ticketKey,
						url: ticketUrl
					} = await uploadFileAndGetPresignedUrl(filesList.ticket, ticketFileSize, 'ticket');
					const {
						jobId: segmentJobId,
						key: segmentKey,
						url: segmentUrl
					} = await uploadFileAndGetPresignedUrl(filesList.segment, segmentFileSize, 'segment');

					const templateId = inputs.application === APPLICATIONS.AIR ? TEMPLATES.AIR_TICKET : TEMPLATES.ANALYTICS_360_AIR;
					const batchIngestion = await batchUpload({
						variables: { templateId },
					});
					const { batchId } = batchIngestion.data.batchUpload;

					const filesSizes = filesList.ticket.size + filesList.segment.size;
					await updateJobAndFile(
						ticketJobId,
						ticketKey,
						ticketUrl,
						filesList.ticket,
						batchId,
						filesSizes
					);
					await updateJobAndFile(
						segmentJobId,
						segmentKey,
						segmentUrl,
						filesList.segment,
						batchId,
						filesSizes,
						filesList.ticket.size,
					);

					await batchRun({
						variables: {
							records: [
								{
									jobId: ticketJobId,
									key: ticketKey,
								},
								{
									jobId: segmentJobId,
									key: segmentKey,
								}
							],
						},
					});
				} else {
					const { jobId, key, url } = await uploadFileAndGetPresignedUrl(filesList.other, fileSize, 'other');
					await updateJobAndFile(jobId, key, url, filesList.other);
					await batchRun({
						variables: {
							records: [{ jobId, key }],
						},
					});
				}
			} else {
				await fetch(url, {
					method: 'PUT',
					body: filesList.other,
				});

				await uploadFileMutation({
					variables: {
						clientId: inputs.client,
						advitoApplicationId: inputs.application,
						sourceId: inputs.source,
						templateId: inputs.template,
						dataStartDate: inputs.fileStartDate,
						dataEndDate: inputs.fileEndDate,
						fileName: filesList.other.name,
						jobStatus: 'running',
						fileSize,
						key,
						countRows: countRows.other,
					},
				});
			}
		} catch (e) {
			toggleModal();
			setParentMessage({ message: e.message, type: 'error' });
		}
	}

	useEffect(() => {
		const setParsedFile = () => {
			if (isFilesSelected) {
				workerInstance.addEventListener('message', async (workerMessage) => {
					if (workerMessage.data.type === MSG_TYPES.PARSING_FINISHED) {
						if (workerMessage.data.error) {
							setParsing(false);
							setParsingError(workerMessage.data.message);
							setParentMessage({
								message: workerMessage.data.message,
								type: 'error'
							});
						} else {
							try {
								setCountRows((countRows) => {
									if (isBatchUpload && (countRows.ticket || countRows.segment)) {
										setMessage(workerMessage.data.message);
										setParsing(false);
										setParentMessage({});
									}

									return {
										...countRows,
										[workerMessage.data.template]: workerMessage.data.countRows
									}
								});

								if (!isBatchUpload) {
									setMessage(workerMessage.data.message);
									setParsing(false);
									setParentMessage({});
								}

								if (inputs.application !== APPLICATIONS.ANALYTICS_360 &&
									inputs.application !== APPLICATIONS.AIR) {
									const result = await client.query({
										query: GET_PRESIGNED_UPLOAD_URL,
										variables: {
											fileName: filesList.other.name,
											applicationId: inputs.application
										},
										fetchPolicy: 'network-only',
										//fetch a POST with
										onError: console.error
									});
									updateSignedUrl(result.data.getPresignedUploadUrl);
								}
							} catch (e) {
								setParsingError(e);
								setMessage('');
								setParsing(false);
								setParentMessage({
									message: e.message,
									type: 'error'
								});
							}
						}
					}
				});

				setParsing(true);
				setParentMessage({
					message: [
						<React.Fragment key="0">Parsing file....</React.Fragment>,
						<Spin key="1" style={{ marginLeft: '10px' }} />,
					],
					type: 'info'
				});
				setParsingError('');
				setMessage('');

				if (isBatchUpload) {
					workerInstance.fileParser(filesList.ticket, getClientName(inputs.client), 'ticket');
					workerInstance.fileParser(filesList.segment, getClientName(inputs.client), 'segment');
				} else {
					workerInstance.fileParser(filesList.other, getClientName(inputs.client), 'other');
				}

			}
		}
		setParsedFile();
	}, [filesList.ticket, filesList.segment, filesList.other]);

	const getClientName = (clientId) => {
		return data.clientList.find((client) => client.id === clientId).clientName;
	}

	if (loading) return <SpinLoader />;
	if (error) return <ErrorMessage error={error} />;

	return (
		<Modal
			title="Confirm File Upload"
			visible={visible}
			okButtonProps={{
				style: {
					display: !isFilesSelected || parsingError || (inputs.application !== APPLICATIONS.ANALYTICS_360 && inputs.application !== APPLICATIONS.AIR && !signedUrl) ? 'none' : ''
				},
				type: 'primary'
			}}
			cancelButtonProps={{ type: 'default' }}
			onOk={() => handleFileUpload(signedUrl, isFilesSelected)}
			onCancel={toggleModal}
		>
			{isFilesSelected && message && <p>{message}</p>}
			{isFilesSelected && parsingError && (
				<p style={{ whiteSpace: 'pre-line' }}>{parsingError}</p>
			)}
			{
				isBatchUpload
					?
					!(filesList.ticket && filesList.segment) && (
						<p>No or one file selected. Please click or drag a files to both upload areas</p>
					)
					:
					!filesList.other && (
						<p>No file selected. Please click or drag a file to upload area</p>
					)
			}
		</Modal>
	);
}

export default UploadConfirmation;

export const USER_ROLE_IDS = {
  ADMIN_ROLE: 12,
};

export const APPLICATIONS = {
  AIR: 2,
  ANALYTICS_360: 3
};

export const TEMPLATES = {
  AIR_TICKET: 8,
  AIR_SEGMENT: 9,
  ANALYTICS_360_AIR: 15,
  ANALYTICS_360_AIR_SEGMENT: 21,
};

export const TEMPLATE_NAMES = {
  TICKET: 'Air Ticket',
  SEGMENT: 'Air Segment',
};

export const MSG_TYPES = {
  PARSING_FINISHED: 'parsing_finished'
};

export const PRACTICE_AREAS = {
  AIR: 'Air',
  HOTEL: 'Hotel',
};

import styled from 'styled-components';
import QRCode from 'qrcode.react';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	min-height: 1000px;
	background: ${props => props.theme.jungleMist};
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Logo = styled.div`
	display: flex;
	width: 100%;
	padding: 2em;
	> img {
		max-width: 200px;
		margin-left: 2em;
	}
`;

export const Title = styled.div`
	font-size: 4em;
	text-align: center;
	color: ${props => props.theme.white};
	line-height: 1.25;
	margin-top: 2em;
`;

export const SubTitle = styled.div`
	font-size: 3em;
	text-align: center;
	color: ${props => props.theme.white};
	margin-top: 1em;
`;

export const FormContainer = styled.div`
	padding: 2em 4em;
	width: 30%;
	max-width: 500px;
`;

export const ButtonRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1em;
`;

export const Link = styled.div`
	color: ${props => props.theme.white};
	cursor: pointer;
	:hover {
		opacity: 0.7;
	}
`;

export const QustomQRCode = styled(QRCode)`
  width: 100%;
  height: 200px;
`;

export const MfaMsg = styled.div`
  margin-bottom: 10px;
`;

import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { Popover } from 'antd';
import { SettingFilled } from '@ant-design/icons';

import { LOGOUT } from 'api';
import { removeUser, getUser } from 'helper';
import colors from 'styles/variables';
import { authClient } from 'index';

import './NavMenu.css';

const LogOut = styled.span`
	color: ${props => props.theme.white};
	cursor: pointer;
	:hover {
		color: ${props => props.theme.white};
		text-decoration: underline;
	}
`;

const NavMenu = () => {
	const [sessionToken, setSessionToken] = useState('');

	useEffect(() => {
		const { sessionToken } = getUser();
		setSessionToken(sessionToken);
	}, []);

	const [logout] = useMutation(LOGOUT, {
		client: authClient,
		onCompleted: () => {
			removeUser();
		},
	});

	const logoutHandler = () => {
		if (sessionToken) {
			logout({ variables: { sessionToken } });
		} else {
			removeUser();
		}
	};

	return (
		<Popover
			content={
				<LogOut onClick={logoutHandler}>
					Logout
				</LogOut>
			}
			trigger="click"
			placement="bottom"
		>
			<SettingFilled style={{ fontSize: '1.5em', color: colors.treePoppy }} />
		</Popover>
	);
}

export default NavMenu;

import React, { useReducer } from 'react';

import {
  CLEAR_TO_INITIAL,
  FAIL_LAST_ACTIVE_STEP,
  UPDATE_STEP_PROGRESS,
  SET_VISIBILITY,
} from './constants';
import UploadProgressBarContext from './progressBarContext';
import progressBarReducer from './progressBarReducer';

export const initState = {
  steps: [
    {
      label: 'File uploading',
    },
    {
      label: 'Pre-processing',
    },
    {
      label: 'PCI validation',
    },
    {
      label: 'Data validation',
    },
    {
      label: 'Uploading to DB',
    }
  ],
  visible: false,
};

const createInitialState = (initialState) => initialState;

const ProgressBarState = ({ children }) => {
  const [state, dispatch] = useReducer(progressBarReducer, initState, createInitialState);

  const updateStepProgress = (newStepState) => {
    dispatch({
      type: UPDATE_STEP_PROGRESS,
      payload: newStepState,
    });
  };

  const setVisibilityProgressBar = (visible) => {
    dispatch({
      type: SET_VISIBILITY,
      payload: visible,
    })
  };

  const clearStepProgressToInitial = () => {
    dispatch({
      type: CLEAR_TO_INITIAL,
      payload: initState,
    });
  };

  const failLastActiveStep = () => {
    dispatch({ type: FAIL_LAST_ACTIVE_STEP });
  };

  return (
    <UploadProgressBarContext.Provider
      value={{
        ...state,
        clearStepProgressToInitial,
        failLastActiveStep,
        updateStepProgress,
        setVisibilityProgressBar,
    }}>
      {children}
    </UploadProgressBarContext.Provider>
  );
};

export default ProgressBarState;

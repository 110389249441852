import React from 'react';
import { Form, Input, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router';
import { useMutation } from '@apollo/client';
import queryString from 'query-string';

import advitoLogo from 'assets/AdvitoLogo.png';
import { RESET_PASSWORD } from 'api';
import ErrorMessage from 'components/common/ErrorMessage';
import SuccessMessage from 'components/common/SuccessMessage';
import { SkeletonLoader } from 'components/common/Loader';
import { authClient } from 'index';

import { CHALLENGE } from './constants';
import Footer from './Footer';
import {
	ButtonRow,
	Container,
	Link,
	Logo,
	Title,
	FormContainer,
} from './styledComponents';

const ResetPassword = ({ userData, setUserData }) => {
	const history = useHistory();
	const location = useLocation();
	const [resetPassword, { loading, error, data }] = useMutation(
		RESET_PASSWORD,
		{
			client: authClient
		}
	);
	const params = queryString.parse(location.search);
	const { t: token = '' } = params;

	const handleSubmit = async ({ password, confirmPassword, confirmationCode }) => {
		try {
			const res = await resetPassword({
				variables: {
					password,
					confirmPassword,
					token,
					...(userData && {
						cognitoId: userData.ChallengeParameters?.USER_ID_FOR_SRP,
						session: userData.Session,
						email: userData.email,
						confirmationCode,
					}),
				},
			});

			if (res.data.resetPassword?.ChallengeName === CHALLENGE.MFA_SETUP
				|| res.data.resetPassword?.accessToken) {
				setUserData(res.data.resetPassword);
			}
		} catch (e) {
			console.error('Error in reset password form: ', e)
		}
	};

	const toLoginHandler = () => {
		if (userData) {
			setUserData(undefined);
		} else {
			history.push('/login');
		}
	};

	return (
		<Container>
			<Logo>
				<img src={advitoLogo} alt="logo" />
			</Logo>
			<Title>Reset Password</Title>
			<FormContainer>
				<Form onFinish={handleSubmit}>
					{loading ? (
						<SkeletonLoader />
					) : (
						<>
							{userData?.forgotPassword && (
								<Form.Item
									name="confirmationCode"
									rules={[
										{ required: true, message: 'Please input confirmation code!' }
									]}
								>
									<Input
										type="number"
										placeholder="Confirmation code"
									/>
								</Form.Item>
							)}
							<Form.Item
								name="password"
								rules={[
									{ required: true, message: 'Please input your password!' }
								]}
							>
								<Input
									prefix={<LockOutlined />}
									type="password"
									placeholder="Password"
								/>
							</Form.Item>
							<Form.Item
								name="confirmPassword"
								rules={[
									{ required: true, message: 'Please input your password!' }
								]}
							>
								<Input
									prefix={<LockOutlined />}
									type="password"
									placeholder="Confirm Password"
								/>
							</Form.Item>
						</>
					)}
					<Form.Item>
						<ButtonRow>
							<Link onClick={toLoginHandler}>Back to Login</Link>
							<Button type="danger" htmlType="submit">
								Reset Password
							</Button>
						</ButtonRow>
						{error && <ErrorMessage error={error} />}
						{data && <SuccessMessage message={'Password has been reset'} />}
					</Form.Item>
				</Form>
			</FormContainer>
			<Footer />
		</Container>
	);
}

export default ResetPassword;
